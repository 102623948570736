import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRefs, onMounted, computed } from 'vue';
import { selectWhiteListSet, updateWhiteList, selectJobByParam } from "/src/api/personnel";
const columns = [{
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '性别',
  dataIndex: 'sex'
}, {
  title: '身份证号',
  dataIndex: 'IDNumber'
}, {
  title: '手机号',
  dataIndex: 'phone'
}, {
  title: '是否购买保险',
  dataIndex: 'isHaveInsurance',
  slots: {
    customRender: 'isHaveInsurance'
  }
}, {
  title: '人员岗位',
  dataIndex: 'jobNo',
  slots: {
    customRender: 'jobNo'
  }
}];
export default defineComponent({
  setup() {
    onMounted(() => {
      selectWhiteListSet1();
      getJobData();
    });
    const id = ref(null); //人员岗位

    const jobsData = ref([{
      value: 1,
      label: '管理人员',
      children: []
    }, {
      value: 0,
      label: '施工人员',
      children: []
    }]); //获取岗位

    const getJobData = async () => {
      let res = await selectJobByParam({
        jobName: '',
        jobType: ''
      });

      if (res.code === 200) {
        let arr = [];
        res.data.forEach(element => {
          arr.push({
            value: element.id,
            label: element.jobName,
            jobType: element.jobType
          });
        });
        jobsData.value.forEach(item => {
          if (item.value === 1) {
            item.children = arr.filter(ele => {
              return ele.jobType === 1;
            });
          } else {
            item.children = arr.filter(ele => {
              return ele.jobType === 0;
            });
          }
        });
      } else {
        _message.error(res.message);
      }
    }; //表格显示人员岗位


    const nameByPositions = txt => {
      if (txt) {
        const obj = jobsData.value.find(key => {
          return key.value === txt[0];
        });

        if (obj.children.length) {
          const obj1 = obj.children.find(key => {
            return key.value === txt[1];
          });
          return obj1.label;
        }
      }
    }; //表格分页改变


    const handleTableChange = (pag, filters, sorter) => {
      current.value = pag.current;
      pageSize.value = pag.pageSize;
      total.value = pag.total;
      selectWhiteListSet1();
    };

    const total = ref('3');
    const current = ref('1');
    const pageSize = ref('10');
    const pagination = computed(() => ({
      total: total.value,
      current: current.value,
      pageSize: pageSize.value
    }));
    const loading = ref(false);
    const isChange = ref(false);
    const formState = reactive({
      openChecked: false //isHaveInsurance: false

    });
    let tableData = ref([]);

    const setChange = () => {
      //console.log(formState.isHaveInsurance);
      let setData = {
        id: id.value,
        isEnableWhilelist: formState.openChecked ? 1 : 0 //isHaveInsurance: formState.isHaveInsurance ? 1 : 0,

      };
      updateWhiteList1(setData);
    }; //查询白名单


    const selectWhiteListSet1 = async () => {
      loading.value = true;
      let res = await selectWhiteListSet({
        pageNumber: current.value == 0 ? '1' : current.value,
        limit: pageSize.value == 0 ? '10' : pageSize.value
      });

      if (res.code === 200) {
        loading.value = false;
        let setData = res.data.settings;
        id.value = setData.id;
        Object.assign(formState, {
          openChecked: setData.isEnableWhilelist == 0 ? false : true //isHaveInsurance: setData.isHaveInsurance==0 ? false : true,

        });
        let data = res.data.whiteList;
        let list = res.data.whiteList.list;
        current.value = data.nowPage;
        pageSize.value = data.pageSize;
        total.value = data.countSize;
        tableData.value = [];

        if (list != null) {
          list.filter((item, index) => {
            tableData.value.push({
              key: index + 1,
              name: item.userName,
              sex: item.sex,
              IDNumber: item.idCard,
              phone: item.userMobile,
              isHaveInsurance: item.isHaveInsurance,
              jobNo: [item.isAdmin, item.jobNo]
            });
          });
        }
      } else {
        loading.value = false;

        _message.error(res.message);
      }
    }; //设置白名单


    const updateWhiteList1 = async val => {
      let res = await updateWhiteList(val);

      if (res.code === 200) {
        _message.success('设置成功！');

        current.value = 1;
        pageSize.value = 10;
        isChange.value = false;
        selectWhiteListSet1();
      } else {
        loading.value = false;

        _message.error(res.message);

        selectWhiteListSet1();
      }
    };

    return {
      formState,
      ...toRefs(formState),
      tableData,
      columns,
      isChange,
      loading,
      pagination,
      handleTableChange,
      setChange,
      selectWhiteListSet1,
      nameByPositions
    };
  }

});